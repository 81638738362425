import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			deviceType: 'pc',
			// deviceType: 'mobile',
			showDebug: false,
			ads: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4688525518274277',
				home_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_insert: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '9170554297', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '7857472625', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '4513123989', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],

				detail_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				detail_masonry: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '9557567105', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '6824699775', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '8244485434', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '8244485434', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
			},
			adPosConfig: {
				homeinsertCutPos: [
					//0为起始，1为需要左侧展示的图片数量。
					//除了0 后面的4，8 都是根据console算出来的，因为右侧图片会有几个缺失
					[0, 1],
					[4, 3],
					[14, 3],
				], //第二个后和第6个后插入fullsize广告
				home: [2], //从第6开始后的第2个（对应之前的8,11
				detail: [3, 5, 8, 11],

				homeTypes: [2],
				homeInsert: [2],
				homeInsertPC: [8],
				sortInsert: [1, 3],
				detailInsert: [1],
				detailInsertPC: [8],
			},

			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4688525518274277',
				home_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_insert: [{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '9170554297', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' }],
				sort: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
				detail_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				detail_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			},
			domainConfig: {
				icon: '/icon/8.png',
				designType: 2,
				IAmTitle: 'Light Eats Recipes',
				IAMTYPE: 'LightEatsRecipes',
				IAMEMAIL: 'lighteatsrecipes.support@gmail.com',
				IAmURL: 'https://lighteatsrecipes.online',
				ABOUTUS: 'Discover healthy, delicious recipes perfect for a light meal. Our site offers easy-to-follow guides for nutritious dishes that satisfy without weighing you down.',
				meta: {
					title: 'Light Meal Recipes - Simple and Healthy Choices',
					des: 'Explore a variety of light meal recipes that offer delicious and healthy options for maintaining energy and wellness.',
				},
				welcome: {
					title: 'Welcome to <span>lighteatsrecipes.online!</span>',
					des: 'Discover the world of healthy eating on our light meals website. Find a wide variety of delicious recipes. Nourish your body with nutritious and delicious light meals.',
				},
				whats: {
					title: 'What is light eats?',
					des: 'Light eats refer to meals that are low in calories, fat, and processed ingredients. They often include fresh fruits, vegetables, lean proteins, and whole grains. Light eats are a great way to maintain a healthy diet and lifestyle.',
				},
				firebase: {
					apiKey: 'AIzaSyBYl9s8a3Ppm24sX-JhJ10b4Kq_9QJ43x8',
					authDomain: 'webs-3411d.firebaseapp.com',
					projectId: 'webs-3411d',
					storageBucket: 'webs-3411d.firebasestorage.app',
					messagingSenderId: '657973516568',
					appId: '1:657973516568:web:94ac25321104366521a66d',
					measurementId: 'G-NCH0MMEM2L',
				},
				ads: 'google.com, pub-8381337407740666, DIRECT, f08c47fec0942fa0',
				adsense: {},
				dietTypeList: [],
				dietFetureList: [],
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
			},
			setDietTypeList(state, list) {
				state.dietTypeList = list
			},
			setDietFetureList(state, list) {
				state.dietFetureList = list
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
			domainConfig: (state) => state.domainConfig,
			dietTypeList: (state) => state.dietTypeList,
			dietFetureList: (state) => state.dietFetureList,
		},
	})
}
